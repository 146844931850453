<template>
  <div class="page-layout animated fadeInRight">
    <template v-if="$slots.pageTitle">
      <slot name="pageTitle"></slot>
    </template>
    <template v-else>
      <div v-if="pageTitle" class="row col-12">
        <h3 class="pb-2">{{ pageTitle }}</h3>
      </div>
    </template>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageLayout',
  props: {
    pageTitle: {
      type: String,
      required: false
    }
  }
})
</script>

<style lang="stylus">
@import '../assets/css/backbarDesignSystem.styl'
</style>
