<template>
  <Select
    v-model="computedModelValue"
    :options="items"
    option-label="name"
    :placeholder="placeholder"
    :checkmark="showCheckmark"
    @change="selectItem"
    :class="[fullWidth ? 'w-full' : '']"
    :dropdownIcon="showDropdownIcon ? 'fa-solid fa-sort-down' : 'fa-solid'"
  >
    <template #footer v-if="$slots.footer">
      <slot name="footer"></slot>
    </template>
  </Select>
</template>

<script lang="ts">
import { defineComponent, ref, type PropType, watch, computed } from 'vue'
import Select from 'primevue/select'
import type { DropdownItem } from '@/types/dropdown.type'
export default defineComponent({
  name: 'DropdownComponentNew',
  components: {
    Select
  },
  props: {
    placeholder: {
      type: String,
      required: false
    },
    showCheckmark: Boolean,
    showDropdownIcon: {
      type: Boolean || String,
      default: true
    },
    items: {
      type: Array as PropType<DropdownItem[]>,
      required: true
    },
    modelValue: {
      default: null,
      required: false
    },
    // action menu handles drop down by entire object of item instead of item.id
    actionMenu: {
      default: false,
      required: false
    },
    formField: {
      default: false,
      required: false
    },
    fullWidth: Boolean
  },
  emits: ['update:modelValue', 'change', 'select'],
  setup(props, { emit }) {
    const internalModelValue = ref(props.modelValue)

    watch(
      () => props.modelValue,
      (newVal) => {
        internalModelValue.value = newVal
      }
    )

    const computedModelValue = computed({
      get() {
        if (!props.actionMenu) {
          return props.items.find((item) => item.id === internalModelValue.value) || null
        } else {
          return props.items.find((item) => item === internalModelValue.value) || null
        }
      },
      set(value) {
        if (!props.actionMenu) {
          const selectedId = value ? value.id : null
          internalModelValue.value = selectedId
          emit('update:modelValue', selectedId)
        } else {
          emit('update:modelValue', value)
        }
      }
    })

    function selectItem(item: any) {
      if (!props.actionMenu) {
        emit('update:modelValue', item.value.id)
        emit('change', item.value.id)
        emit('select', item.value.id)
      } else {
        emit('update:modelValue', item.value)
        emit('change', item.value)
        emit('select', item.value)
      }
    }

    return {
      computedModelValue,
      internalModelValue,
      selectItem
    }
  }
})
</script>
<style>
.p-select-dropdown-icon.fa-solid.fa-sort-down {
  margin-top: -5px;
}
.p-select-option {
  line-height: 20px;
}
.p-select-option-blank-icon {
  margin-right: var(--padding-xs);
}

.p-select-option:active {
  background-color: var(--color-surface-pressed);
}

.p-select-list-container {
  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background: #fbfbfb;
    outline: 1px solid #e8e8e8;
    border-top-right-radius: var(--border-radius-sm);
    border-bottom-right-radius: var(--border-radius-sm);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--icon-subdued);
    border-radius: 100px;
    border: 3px solid #fbfbfb;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--icon-hover);
  }
}
</style>
