// Global
// TODO: fill remaining icons const
export enum Icons {
  ArrowUp = 'fa-solid fa-arrow-up-wide-short',
  ArrowLeft = 'fa-regular fa-arrow-left',
  ArrowRight = 'fa-solid fa-arrow-right',
  ArrowsLeftAndRight = 'fa-solid fa-arrow-right-arrow-left',
  Bars = 'fa-solid fa-bars',
  Bolt = 'fa-solid fa-bolt',
  Calendar = 'fa-solid fa-calendar',
  CircleCheck = 'fa-solid fa-circle-check',
  CancelCircle = 'fa-solid fa-circle-xmark',
  InfoCircle = 'fa-solid fa-circle-info',
  List = 'fa-solid fa-list',
  PriceTag = 'fa-solid fa-tags',
  Store = 'fa-solid fa-store',
  WarningTriangle = 'fa-solid fa-triangle-exclamation',
  Close = 'fa-solid fa-xmark',
  Plus = 'fa-solid fa-plus',
  Warning = 'fa-solid fa-warning',
  FileDownload = 'fa-solid fa-file-arrow-down',
  XMark = 'fa-solid fa-xmark',
  Checkmark = 'fa-solid fa-check',
  Pencil = 'fa-solid fa-pencil',
  Filter = 'fa-solid fa-filter',
  Boxes = 'fas fa-boxes',
  PenSquare = 'fas fa-pen-square',
  ExchangeAlt = 'fas fa-exchange-alt',
  ShoppingCart = 'fas fa-shopping-cart',
  UpRightFromSquare = 'fas fa-up-right-from-square'
}

export enum IconAlignment {
  Right = 'right',
  Left = 'left',
  None = 'none'
}

// ButtonComponent
export enum ButtonTypes {
  Default = 'default',
  Secondary = 'secondary',
  Critical = 'critical',
  Primary = 'primary'
}

// TagsComponent
export enum TagStyle {
  Default = '',
  Black = 'black',
  RedIcon = 'red-icon',
  GreenIcon = 'green-icon'
}

export enum TagStatuses {
  Critical = 'critical',
  Warning = 'warning',
  Success = 'success'
}

export enum TagSize {
  Default = '',
  Small = 'small',
  XSmall = 'xsmall',
  XXSmall = 'xxsmall'
}

// CardsComponent
export enum CardStyle {
  Default = 'default',
  Nested = 'nested',
  Product = 'product',
  KPI = 'kpi'
}

export enum NestedCardStyle {
  Default = '',
  Filter = 'filter',
  Calendar = 'calendar'
}

export enum ButtonCardStyle {
  Filter = 'filter',
  Product = 'product'
}

export enum BannerTypes {
  Default = '',
  Info = 'info',
  Success = 'success',
  Critical = 'critical',
  Warning = 'warning'
}

export const BannerToIcon: Record<BannerTypes, Icons | String> = {
  [BannerTypes.Default]: '',
  [BannerTypes.Info]: Icons.InfoCircle,
  [BannerTypes.Success]: Icons.CircleCheck,
  [BannerTypes.Critical]: Icons.CancelCircle,
  [BannerTypes.Warning]: Icons.WarningTriangle
}

// StandaloneLinksComponent
export enum StandaloneLinkTypes {
  Default = '',
  Critical = 'interactive-critical'
}

// Order Status Codes
export enum StatusCode {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Staged = 'Staged',
  Loaded = 'Loaded',
  Delivered = 'Delivered',
  Voided = 'Voided',
  Invoiced = 'Invoiced'
}

// LabelComponent
export enum LabelStatuses {
  Critical = 'critical',
  Warning = 'warning',
  Success = 'success'
}

export enum DatePickerSelectionTypes {
  Range = 'range',
  Multiple = 'multiple',
  Default = 'single'
}

export enum RowContentAlignment {
  Default = 'justify-content-start',
  Right = 'justify-content-end',
  Center = 'justify-content-md-center'
}
