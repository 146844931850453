import PrimeVue from 'primevue/config'
import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'

export const BackbarStylingPreset = definePreset(Aura, {
  primitive: {
    borderRadius: {
      none: '0',
      xs: '2px',
      sm: 'var(--border-radius-small)',
      md: 'var(--border-radius)',
      lg: '8px',
      xl: '12px'
    }
  },
  semantic: {
    iconSize: '14px',
    formField: {
      paddingX: 'var(--padding-sm)',
      paddingY: 'var(--padding-sm)',
      borderRadius: '{border.radius.sm}'
    },
    colorScheme: {
      light: {
        formField: {
          hoverColor: 'var(--color-surface-hover)',
          color: 'var(--color-text)'
        },
        overlay: {
          select: {
            hoverColor: 'var(--color-surface-hover)',
            focusColor: 'var(--color-text)'
          }
        },
        list: {
          option: {
            color: 'var(--color-text-default)',
            focusColor: 'var(--color-text-default)',
            selectedColor: 'var(--color-text-default)',
            selectedFocusColor: 'var(--color-text-default)',
            focusBackground: 'var(--color-surface-hover)',
            selectedFocusBackground: 'var(--color-surface-hover)',
            selectedBackground: 'transparent'
          }
        },
        text: {
          color: 'var(--color-text-default)'
        },
        primary: {
          color: '{zinc.950}',
          inverseColor: '#ffffff',
          hoverColor: '{zinc.900}',
          activeColor: '{zinc.800}'
        },
        highlight: {
          background: 'var(--color-surface-hover)',
          focusBackground: 'var(--color-surface-hover)',
          color: 'var(--color-text-default)',
          focusColor: '#ffffff'
        }
      }
    }
  },
  components: {
    skeleton: {
      root: {
        borderRadius: 'var(--border-radius-sm)'
      },
      colorScheme: {
        light: {
          root: {
            background: 'var(--color-surface-hover)',
            animationBackground: 'rgba(255,255,255,0.4)'
          }
        }
      }
    },
    button: {
      secondary: {
        focus: {
          ring: {
            color: '#774ee2'
          }
        },
        active: {
          border: {
            color: '#3ae333'
          }
        }
      },
      text: {
        secondary: {
          hover: {
            background: '#AE99AE'
          }
        }
      },
      icon: {
        only: {
          background: '#FFFFFF',
          width: '24px'
        }
      }
    },
    dialog: {
      color: 'var(--color-text)',
      header: {
        padding: 'var(--padding-lg)'
      },
      footer: {
        padding: 'var(--padding-lg)'
      },
      content: {
        padding: 'var(--padding-lg)'
      },
      border: {
        radius: 'var(--modal-radius)'
      },
      shadow: '0px 4px 24px 0px rgba(12, 28, 25, 0.16)',
      title: {
        font: {
          size: '16px',
          weight: '700'
        }
      }
    },
    select: {
      placeholder: {
        color: 'var(--color-text)'
      },
      dropdown: {
        color: 'var(--color-text)',
        width: '2rem'
      },
      overlay: {
        border: {
          radius: 'var(--border-radius-sm)'
        }
      },
      list: {
        padding: 'var(--padding-sm)'
      },
      option: {
        color: 'var(--color-text)',
        padding: 'var(--padding-xs)',
        border: {
          radius: 'var(--border-radius-sm)'
        },
        focusBackground: 'var(--color-surface-hover)',
        selectedBackground: 'transparent',
        selectedFocusBackground: 'var(--color-surface-hover)'
      },
      border: {
        color: 'var(--border-default-color)',
        radius: 'var(--border-radius-sm)'
      },
      hover: {
        border: {
          color: 'var(--border-hover-color)'
        }
      },
      focus: {
        border: {
          color: 'var(--border-pressed-color)'
        }
      },
      checkmark: {
        gutter: {
          start: '0',
          end: 'var(--padding-xs)'
        }
      }
    },
    datatable: {
      colorScheme: {
        light: {
          root: {
            borderColor: 'var(--border-default-color)'
          },
          row: {
            stripedBackground: '{surface.50}'
          },
          bodyCell: {
            selectedBorderColor: 'var(--border-default-color)'
          }
        }
      },
      headerCell: {
        gap: 'var(--padding-sm)'
      },
      row: {
        background: 'var(--color-surface-default)',
        hoverBackground: 'var(--color-surface-hover)',
        selectedBackground: 'var(--color-surface-pressed)',
        selectedColor: 'var(--color-text)',
        borderColor: 'var(--border-default-color)'
      },
      bodyCell: {
        borderColor: 'var(--border-default-color)'
      },
      footer: {
        borderColor: 'var(--border-default-color)',
        borderWidth: '0 1px 1px 1px',
        padding: '10px 8px'
      }
    }
  }
})
