import { createApp, watch } from 'vue'
import type { App } from 'vue'
import { createPinia } from 'pinia'
import { createHead, VueHeadMixin } from '@unhead/vue'
import $ from 'jquery'
import flatpickr from 'vue-flatpickr-component'
import PrimeVue, { defaultOptions } from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import VueTagsInput from '@sipec/vue3-tags-input'
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import VueSelect from 'vue-select'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import draggable from 'vuedraggable'

import MainApp from './App.vue'
import router from './router/router'

import ClickOutside from '@/directives/click-outside'

import UtilsPlugin from '@/plugins/utils.plugin.js'
import AlertsPlugin from '@/plugins/alerts.plugin.js'
import FiltersPlugin from '@/plugins/filters.plugin.js'
import { useGtmStore } from '@/stores/google-tag-manager.js'

import AlertComponent from '@/components/AlertComponent.vue'
import AlertModal from '@/components/AlertModal.vue'
import BBConfirmComponent from '@/components/BBConfirmComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import BannerComponent from '@/components/BannerComponent.vue'
import Calendar from '@/components/Calendar.vue'
import CardComponent from './components/cards/CardComponent.vue'
import PageLayout from './components/PageLayout.vue'
import RowContainerComponent from './components/RowContainerComponent.vue'
import DidbuysItemReviewComponent from '@/components/DidbuysItemReviewComponent.vue'
import DropdownComponent from './components/DropdownComponent.vue'
import DropdownComponentNew from './components/DropdownComponentNew.vue'
import KPICardComponent from './components/cards/default/KPICardComponent.vue'
import NavBarComponent from './components/NavBar/NavBarComponent.vue'
import TagsComponent from '@/components/TagsComponent.vue'
import TagsInput from '@/components/TagsInput.vue'
import VendorCarouselComponent from '@/components/VendorCarouselComponent.vue'
import VenueSwitcherComponent from '@/components/VenueSwitcherComponent.vue'
import { useUserStore } from '@/stores/user.js'
import type { UserType } from '@/types/user.type.js'
import type { VenueType } from '@/types/venue.type.js'
import type { OrganizationType } from '@/types/organization.type.js'
import { BackbarStylingPreset } from './primeVueStyling'
import VueDatePicker from '@vuepic/vue-datepicker'
import DatePickerComponent from './components/DatePickerComponent.vue'
import DualDatePickerComponent from './components/DualDatePickerComponent.vue'
import RangeDatePickerComponent from './components/RangeDatePickerComponent.vue'
import LabelComponent from './components/LabelComponent.vue'
import CustomDropDown from './views/inventory/inventory-items-import-wizard/shared/CustomDropDown.vue'

window.$ = $
window.jQuery = $
import('jquery-slimscroll')

const app: App = createApp(MainApp)
const head = createHead()
const FilePond = vueFilePond(FilePondPluginImagePreview, FilePondPluginFileValidateType)
app.mixin(VueHeadMixin)
app.use(createPinia())
const userStore = useUserStore()
Bugsnag.start({
  apiKey: 'a49dc6f1518ba6a04c1c69484b947491',
  plugins: [new BugsnagPluginVue()],
  releaseStage: import.meta.env.VITE_USER_NODE_ENV,
  onError(event) {
    const {
      user = {} as UserType,
      currentVenue = {} as VenueType,
      currentOrganization = {} as OrganizationType
    } = userStore
    if (user.id) {
      event.setUser(user.id.toString(), `${user.first_name} ${user.last_name}`, user.email)
    }
    if (currentVenue.id) {
      event.addMetadata('venue', currentVenue)
    }
    if (currentOrganization.id) {
      event.addMetadata('organization', currentOrganization)
    }
  }
})
const bugsnagVue = Bugsnag.getPlugin('vue')
if (bugsnagVue) {
  app.use(bugsnagVue)
}
const bugsnagInstance = Bugsnag
const gtmStore = useGtmStore()
window.mainVm = {
  currentOrganization: {
    name: gtmStore.currentOrganization.name,
    plan: gtmStore.currentOrganization.plan
  },
  currentVenue: {
    vip_verification_status: gtmStore.currentVenue.vip_verification_status
  },
  user: {
    id: gtmStore.user.id,
    email: gtmStore.user.email
  },
  selected_plan: {
    name: gtmStore.selected_plan.name
  },
  eACV: gtmStore.eACV,
  orders: gtmStore.orders,
  items: gtmStore.items
}
watch(
  () => gtmStore,
  (newValue) => {
    Object.assign(window.mainVm.currentOrganization, newValue.currentOrganization)
    Object.assign(window.mainVm.currentVenue, newValue.currentVenue)
    Object.assign(window.mainVm.user, newValue.user)
    window.mainVm.items = newValue.items
    window.mainVm.orders = newValue.orders
    window.mainVm.eACV = newValue.eACV
    window.mainVm.selected_plan.name = newValue.selected_plan.name
  },
  { deep: true }
)
app.use(head)
app.use(router)
app.use(AlertsPlugin)
app.use(FiltersPlugin)
app.use(UtilsPlugin)
app.use(PrimeVue, {
  ripple: false,
  locale: {
    ...defaultOptions.locale,
    dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  },
  theme: {
    preset: BackbarStylingPreset,
    options: {
      darkModeSelector: '.backbar-dark-mode'
    }
  }
})
app.component('AlertModal', AlertModal)
app.component('AlertComponent', AlertComponent)
app.component('BbConfirm', BBConfirmComponent)
app.component('ButtonComponent', ButtonComponent)
app.component('BannerComponent', BannerComponent)
app.component('DropdownComponent', DropdownComponent)
app.component('CustomDropdownComponent', CustomDropDown)
app.component('DropdownComponentNew', DropdownComponentNew)
app.component('VueDatePicker', VueDatePicker)
app.component('Calendar', Calendar)
app.component('CardComponent', CardComponent)
app.component('DidbuysItemReview', DidbuysItemReviewComponent)
app.component('LabelComponent', LabelComponent)
app.component('KPICardComponent', KPICardComponent)
app.component('PageLayout', PageLayout)
app.component('RowContainerComponent', RowContainerComponent)
app.component('DatePickerComponent', DatePickerComponent)
app.component('DualDatePickerComponent', DualDatePickerComponent)
app.component('RangeDatePickerComponent', RangeDatePickerComponent)
app.component('NavBarComponent', NavBarComponent)
app.component('VendorCarouselComponent', VendorCarouselComponent)
app.component('VenueSwitcher', VenueSwitcherComponent)
app.component('FilePond', FilePond)
app.component('FlatPickr', flatpickr)
app.component('VueTagsInput', VueTagsInput)
app.component('TagsComponent', TagsComponent)
app.component('TagsInput', TagsInput)
app.component('VSelect', {
  extends: VueSelect,
  methods: {
    maybeAdjustScroll: () => false
  }
})
app.component('Draggable', draggable)
app.directive('click-outside', ClickOutside)
app.directive('bbtooltip', Tooltip)
app.mount('#app')

export { bugsnagInstance as Bugsnag }
