<template>
  <div
    :class="['new-dropdown', fullWidth && 'w-100', unstyled && 'unstyled']"
    @click.stop="toggleDropdown"
  >
    <div
      style="z-index: 10"
      :class="['selected-item container d-flex', fullWidth && 'w-100']"
      ref="newDropdown"
    >
      <div class="w-100">
        <div class="d-flex justify-content-between align-items-center">
          <div class="text-truncate flex-fill">
            <i v-if="icon" :class="['left-icon fa-xs', icon]"></i>
            <i v-if="skipIcon" class="custom-item-icon-header fas fa-ban"></i>
            {{
              modelValue
                ? items.find((item: any) => item.id === modelValue || items.name === modelValue)
                    ?.name
                : placeholder
            }}
          </div>
          <i class="fas fa-caret-down dropdown-caret"></i>
        </div>
      </div>
    </div>

    <ul
      :class="['new-dropdown-menu', { show: isOpen }, dropdownPosition]"
      :style="fullWidth ? 'min-width: 100%; ' : ''"
      ref="newDropdownMenu"
    >
      <li
        style="padding-left: 8px; display: flex; align-items: center"
        v-if="placeholder && showPlaceholderInList"
        @click="(value) => clearSelection(value)"
        value="0"
        :className="['with-selected']"
      >
        {{ placeholder }}
      </li>

      <li
        :style="
          disabledItems?.find((column) => column === item.id)
            ? 'color: #ABB2B2; padding-left: 8px'
            : 'padding-left: 8px'
        "
        v-for="(item, index) in items"
        :key="index"
        @click="
          disabledItems?.filter((column) => column === item.id)?.length > 0 ? {} : selectItem(item)
        "
        :className="[selectedIcon && item.id !== modelValue && 'with-selected']"
      >
        <div class="skip-column-icon" v-if="item.name === 'Skip column'">
          <i class="custom-item-icon fas fa-ban"></i>
          {{ item.name }}
        </div>
        <div v-else>
          <i
            v-if="selectedIcon && item.id === modelValue"
            class="fas fa-check selected-icon-padding"
          ></i>
          {{ item.name }}
        </div>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import type { DropdownItem } from '@/types/dropdown.type'
import { defineComponent, type PropType } from 'vue'

export default defineComponent({
  name: 'CustomDropDown',
  data() {
    return {
      isOpen: false,
      dropdownPosition: 'below'
    }
  },
  props: {
    customClass: {
      default: {}
    },
    placeholder: {
      type: String,
      required: false
    },
    showPlaceholderInList: Boolean,
    disablePlaceholder: Boolean,
    disabledItems: { type: Array as PropType<DropdownItem[]> },
    items: {
      type: Array as PropType<DropdownItem[]>,
      required: true
    },
    // Fas icon to display for top level selected input
    icon: {
      type: String,
      default: null,
      required: false
    },
    skipIcon: {
      type: Boolean,
      default: false,
      required: false
    },
    // When true, displays checkmark next to selected item in dropdown
    selectedIcon: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    modelValue: {
      required: false
    },
    unstyled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    let placeholderText
    if (props.placeholder) {
      placeholderText = props.placeholder
    }

    function selectItem(item: any) {
      emit('change', item)
      emit('update:modelValue', item.id)
    }

    function clearSelection(value) {
      emit('update:modelValue', 0)
      emit('change', 0)
      emit('update:modelValue', 0)
    }

    return {
      placeholderText,
      selectItem,
      clearSelection
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen
      this.positionDropdown()
    },
    positionDropdown() {
      const dropdown = this.$refs.newDropdown as HTMLElement
      const menu = this.$refs.newDropdownMenu as HTMLElement

      if (dropdown && menu) {
        const dropdownRect = dropdown.getBoundingClientRect()
        const menuHeight = 160
        const spaceBelow = window.innerHeight - dropdownRect.bottom
        const spaceAbove = dropdownRect.top

        if (spaceBelow < menuHeight && spaceAbove > spaceBelow) {
          this.dropdownPosition = 'above'
        } else {
          this.dropdownPosition = 'below'
        }
      }
    },
    handleClickOutside(event: MouseEvent) {
      if (!this.$el.contains(event.target as HTMLElement) && this.isOpen) {
        this.isOpen = false
      }
    },
    calculateDropdownWidth() {
      const dropdown = this.$refs.newDropdown
      const menu = this.$refs.newDropdownMenu
      if (menu) {
        this.toggleDropdown()
        const menuWidth = menu.clientWidth
        this.toggleDropdown()
      }
    }
  },
  mounted() {
    this.calculateDropdownWidth()
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  watch: {
    items() {
      this.calculateDropdownWidth()
    },
    modelValue() {
      this.calculateDropdownWidth()
    }
  }
})
</script>

<style scoped lang="stylus">
.new-dropdown
  position relative
  display inline-block
  user-select none
  background-color #ffffff

  & .new-dropdown-menu.temp-visible
    display block
    background-color #ffffff




  & .selected-item:hover
    border 1px solid #313635

  & .selected-item
    background-color #ffffff
    padding 0.5rem
    border 1px solid #dbdfdf


    cursor pointer
    border-radius 3px
    box-shadow 0px 1px 1px 0px rgba(219, 223, 223, 0.75)
    transition border-color .2s cubic-bezier(0.645, 0.045, 0.355, 1)
    transition background .2s cubic-bezier(0.645, 0.045, 0.355, 1)

    i

    i.custom-item-icon-header {
      color #313635 !important;

    }

    i.left-icon
      margin-right 2px
      color #313635

  & .new-dropdown-menu
    position absolute
    left 0
    z-index 9999999999 !important
    display none
    list-style none
    background-color #fff
    border 1px solid #dbdfdf
    border-radius 3px
    font-size 12px
    padding 8px
    padding-left 16px
    box-shadow 0px 2px 4px 0px rgba(219, 223, 223, 0.75)
    white-space nowrap
    max-height 310px
    overflow-y scroll
    overflow-x auto
    max-width 176px

    &::-webkit-scrollbar
      width 8px
      height 8px


    &::-webkit-scrollbar-thumb
      background-color #ABB2B2
      border-radius 10px

    &::-webkit-scrollbar-track
      background-color #f2f2f2




    &.show
      display block

    &.above
      bottom 100%
      margin-bottom 3px

    &.below
      top 100%
      margin-top 3px

    ul.new-dropdown-menu


    li
      cursor pointer
      min-height 28px
      padding 8px
      padding-right 12px
      display flex
      align-items center


      &:hover
        background-color #F2F4F6




      &.disabled
        cursor not-allowed
        opacity 0.5


.unstyled
  background-color transparent

  .selected-item
    border none
    border-radius 3px
    border-bottom 1px solid #dbdfdf
    box-shadow none

.dropdown-caret
  padding-left 12px

.with-selected
  padding-left 12px
  min-height 28px
  padding 8px
  padding-right 12px
  display flex
  align-items center

.selected-icon-padding
  padding-right 2px
  margin-left -19px

.custom-item-icon
  color: #0074dd !important
  margin-left: -2px
  font-size: 14px
  padding-right: 4px


.skip-column-icon
  color:  #0074dd
  font-family: Nunito
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 28px
  margin-left: 5px
  min-height: 8px
  max-height: 8px

  display: flex
  align-items: center
  padding-right 2px
  margin-left 0px

  &:hover
     background-color #F2F4F6


  .dropdown-custom-slot
    padding-right -10px
    min-height 28px
    padding 8px
    padding-right 12px
    display flex
    align-items center
</style>
