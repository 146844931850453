<template>
  <CardComponent :cardStyle="CardStyle.KPI">
    <template v-slot>
      <div class="kpi-value">
        <div v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
        <div v-else-if="value !== null && value !== undefined">{{ value }}</div>
        <div v-else>-</div>
      </div>
      <div class="d-flex align-items-center card-title">
        <TagsComponent
          v-if="titleIcon"
          :icon="titleIcon"
          additional-class="black"
          :tagSize="TagSize.XSmall"
          style="margin-right: 3px"
        />
        <p v-if="title">{{ title }}</p>
      </div>
      <div v-if="link" class="kpi-link">
        <StandaloneLink
          :linkLabel="linkLabel"
          :linkType="StandaloneLinkTypes.Default"
          :iconAlignment="IconAlignment.Right"
          :icon="Icons.ArrowRight"
          :link="link"
        />
      </div>
    </template>
  </CardComponent>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import CardComponent from '../CardComponent.vue'
import StandaloneLink from '../../StandaloneLinkComponent.vue'
import TagsComponent from '../../TagsComponent.vue'
import {
  CardStyle,
  Icons,
  IconAlignment,
  StandaloneLinkTypes,
  TagSize
} from '@/enums/components.enum'

export default defineComponent({
  name: 'KPI Card Component',
  components: { CardComponent, StandaloneLink, TagsComponent },
  props: {
    value: {
      type: [Number, String],
      required: false
    },
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: false
    },
    linkLabel: {
      type: String,
      required: false
    },
    titleIcon: {
      type: String as PropType<Icons>,
      required: false
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup() {
    return {
      CardStyle,
      Icons,
      IconAlignment,
      StandaloneLinkTypes,
      TagSize
    }
  }
})
</script>
