<template>
  <div
    v-if="showBanner"
    :class="[
      'banner-wrapper',
      isMini ? 'mini-banner' : '',
      fullWidth ? 'w-100' : '',
      'd-flex',
      bannerType ? bannerType + '-banner' : ''
    ]"
  >
    <div>
      <i :class="iconType" :style="{ color: iconColor }"></i>
    </div>
    <div class="text-wrapper">
      <p v-if="title" class="title">{{ title }}</p>
      <p v-if="description">{{ description }}</p>
      <slot name="description"></slot>
      <ButtonComponent v-if="buttonLabel" buttonType="secondary" @click="triggerEmit" :small="true">
        {{ buttonLabel }}
      </ButtonComponent>
    </div>
    <div v-if="isDismissable" class="close-button">
      <i :class="closeIcon" @click="closeBanner"></i>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BannerTypes, Icons, BannerToIcon } from '@/enums/components.enum'
import ButtonComponent from './ButtonComponent.vue'

export default defineComponent({
  components: { ButtonComponent },
  name: 'BannerComponent',
  props: {
    isMini: Boolean,
    title: String,
    description: String,
    isDismissable: Boolean,
    bannerType: {
      type: String,
      default: BannerTypes.Default,
      validator: (value: BannerTypes) => Object.values(BannerTypes).includes(value)
    },
    buttonLabel: String,
    fullWidth: Boolean
  },
  computed: {
    icons() {
      return Icons
    },
    iconType(): string {
      return BannerToIcon[this.bannerType as BannerTypes]
    },
    iconColor() {
      switch (this.bannerType) {
        case BannerTypes.Critical:
          return '#CA3534' // $icon-critical
        case BannerTypes.Info:
          return '#007A99' // $icon-highlight
        case BannerTypes.Success:
          return '#326F65' // $icon-success
        case BannerTypes.Warning:
          return '#A97F00' // $icon-warning
        default:
          return '#007A99' // $icon-highlight
      }
    }
  },
  data() {
    return {
      showBanner: true,
      closeIcon: Icons.Close
    }
  },
  methods: {
    closeBanner() {
      this.showBanner = false
    },
    triggerEmit() {
      this.$emit('button-click')
    }
  }
})
</script>
<style scoped>
.banner-wrapper {
  padding: 12px 8px;
  align-items: flex-start;
  gap: 8px;
  border: 1px solid;
}
.mini-banner {
  font-size: 12px;
}
.text-wrapper > * {
  margin-bottom: 2px;
}
.title {
  font-weight: 700;
}
.info-banner {
  background-color: #eafbff; /* Surface/Highlight */
  border-color: #79e4ff; /* Border/Highlight */
}
.success-banner {
  background-color: #e6f4f1; /* Surface/Success */
  border-color: #7ec5b9; /* $seafoam, Border/Success */
}
.critical-banner {
  background-color: #fcf2f2; /* Surface/Critical */
  border-color: #e9abaa; /* Border/Critical */
}
.warning-banner {
  background-color: #fffae9; /* Surface/Warning */
  border-color: #ffe493; /* Border/Warning */
}
.close-button {
  margin-left: auto;
}
</style>
